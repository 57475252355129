import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import {updateLanguageRule} from "../../../api_methods";
import {
  api,
  loginRedirect,
  csrftoken,
  notify,
} from '../../../../utils/index_tagging_tool'
import _ from 'lodash'
import {Col} from "reactstrap";

// Campo testo che consente l'impostazione del valore di una lingua per una regola
function LanguageField({ language, rule, disabled, ratio }) {
  const [val, setVal] = useState(rule.rule[language] ? rule.rule[language] : '')

  useEffect(() => {
    // Quando cambia l'indice sincronizzo
    setVal(rule.rule[language] ? rule.rule[language] : '')
  }, [rule.id])

  const handleBlur = () => {
    // Creo un piccolo dict per mandare i dati a backend
    const data = {}
    data[language] = val

    // Aggiorno il valore per il frontend
    rule.rule[language] = val

    // Salva a backend il valore in un campo della json_rule che ha come chiave il nome della lingua, sull'onblur
    updateLanguageRule(rule.id, data)
  }

  return (
    <div style={{ display: 'inline-block', width: ratio.tag }}>
      <fieldset className="w-seo-control-field">
        <Form.Control
          type="text"
          title={'nome'}
          value={val}
          disabled={disabled}
          onChange={(e) => setVal(e.target.value)}
          onBlur={handleBlur}
          className="form-control"
        />
      </fieldset>
    </div>
  )
}

// Componente per le singole righe di una lingua (sono in pratica una traduzione del value di una regola in un'altra lingua)
// Crea tanti field quante sono le lingue selezionati
export function LanguageRow({
  showLanguages,
  selectedLanguages,
  rule,
  disabled,
  ratio,
}) {
  return (
    <>
      {showLanguages && (
        <>
          {selectedLanguages.map((lang) => (
            <LanguageField language={lang} rule={rule} disabled={disabled} ratio={ratio} />
          ))}
        </>
      )}
    </>
  )
}
