export function getRuleFromValue(val) {
  // Le regole dipendenti sono generate così a backend, per gestire i campi lista
  // Quindi a partire dal valore di una regola, per risalire all'operando della regola dipendente lo devo generare così
  let rule = `^(${val},)|` // Comincia con la parola e segue una virgola
  rule = rule + `(,${val})$|` // Finisce con la parola ed è preceduta da una virgola
  rule = rule + `(,${val},)|` // E' preceduta e seguita da una virgola
  return rule + `^(${val})$` // C'è solo la parola
}

export function keywordRules(rules) {
  return rules.filter(
    (r) => r.rule.conditions.filter((x) => x.field === 'Keyword').length > 0 // Se tutte le sue condizioni contengono Keyword
  )
}

export function NonKeywordRules(rules) {
  return rules.filter(
    (r) => r.rule.conditions.filter((x) => x.field !== 'Keyword').length > 0 // Se tutte le sue condizioni contengono Keyword
  )
}

export const isValidRegex = (regex) => {
  /**
    Funzione che verifica se la regex è valida
  */

  try{
    new RegExp(regex)
  }catch (e) {
    return false
  }

  return !regex.endsWith("|");


}

export const buildRuleStructure = (rule, tagIT, tagEN, containsRegex, notContainsRegex='~') => {
  /**
   * Creates a rule object, based on an existing rule.
   * This function should be used for rule update functionalities.
   * @type {{report_id: *, index, rule: {conditions: [{next: string, field: string, operator: string, operand},{field: string, operator: string, operand: string}], value}, id, priority, type: string}}
   */
  const newRule = {
    id: rule.id,
    report_id: rule.report_id,
    priority: rule.priority,
    index: rule.index,
    type: 'manual',
    rule: {
      value: tagIT,
      conditions: [
        {
          field: 'Keyword',
          operator: 'REGEX CONTAINS (IGNORE CASE)',
          operand: containsRegex,
          next: 'AND',
        },
        {
          field: 'Keyword',
          operator: 'REGEX NOT CONTAINS (IGNORE CASE)',
          // ~ lo uso come placeholder, per rendere la regola sempre vera in caso questa non sia settata
          operand: notContainsRegex ? notContainsRegex : '~',
        },
      ],
    },
  }

  if (tagEN) {
    newRule.rule.Inglese = tagEN
  }

  return newRule
}

export function checkSeoRule(rule, contieneError, nonContieneError) {
  /**
   * Requisiti minimi che una regola deve soddisfare per avviare comunicazione con backend
   * @type {boolean}
   */
  let check = true
  check = check && typeof rule.priority === 'number'
  check = check && rule.type === 'manual'
  check = check && rule.rule.conditions.length >= 1
  check = check && ((rule.rule.value && rule.rule.value !== '') || (rule.rule.Inglese && rule.rule.Inglese !== ''))
  check = check && rule.rule.conditions[0].operand !== ''
  check = check && contieneError === false
  check = check && nonContieneError === false
  return check
}

export function unifyRules(customDim, savedDim) {
  /**
   * Pongo quelle non salvate dopo, per dargli priorità (rimanendo coeranti con backend reports.seo.seo_base)
   */
  if (customDim.linked) return [...savedDim.rules, ...customDim.rules]
  return customDim.rules
}
